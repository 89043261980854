.gridpage {
    // flex: 1 1 0px;
    height: 100%;

    // display: flex;
    // flex-direction: column;
    // height: 100%;
}

.grid-wrapper {
    flex: 1 1 0px;
    width: 100%;
}

#myGrid {
    height: 100%;
}