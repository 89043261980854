.pencilIcon:focus-visible {
    outline: 2px solid #007bff; /* Színes körvonal a fókuszhoz */
}

.pencilIcon {
    // outline: 1px solid #30328f; /* Színes körvonal a fókuszhoz */
    outline: none; /* Színes körvonal a fókuszhoz */
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
}