.MsoNormal {
    color: beige;
    text-align: justify;
}

.greatingpage {
    padding: 20pt;
}

.italic-text {
    font-style: italic;
}

.underline-text {
    text-decoration: underline;
}

.headline {
    background-color: rgb(98, 220, 228);
    font-style: italic;
    color: black;
}