.emptyrow {
    padding: 10pt;
    background-color: silver;
}

.rowhead {
    text-align: center;
    background-color: silver;
    color: black;
}

.topbottomright {
    border-top-style: groove;
    border-top-color: silver;
    border-bottom-style: groove;
    border-bottom-color: silver;
    border-right-color: silver;
    border-right-style: groove;
}

.topbottom {
    border-top-style: groove;
    border-top-color: silver;
    border-bottom-style: groove;
    border-bottom-color: silver;
}

.bottom {
    border-bottom-style: groove;
    border-bottom-color: silver;
}

.bottomright {
    border-bottom-style: groove;
    border-bottom-color: silver;
    border-right-style: groove;
    border-right-color: silver;
}

.right {
    border-right-style: groove;
    border-right-color: silver;
}
